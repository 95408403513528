export const templateItems = [
  {
    group: "抛个硬币做出选择",
    label: '正面',
    weight: 6,
    order: 0
  },
  {
    group: "抛个硬币做出选择",
    label: '反面',
    weight: 2,
    order: 1
  },

  {
    group: '一会去吃什么？',
    label: '火锅',
    weight: 1,
    order: 2
  },
  {
    group: '一会去吃什么？',
    label: '自助餐',
    weight: 1,
    order: 3
  },
  {
    group: '一会去吃什么？',
    label: '夜市',
    weight: 1,
    order: 4
  },
  {
    group: '一会去吃什么？',
    label: '国际连锁沙县酒楼',
    weight: 1,
    order: 5
  },
  {
    group: '一会去吃什么？',
    label: '麻辣烫',
    weight: 1,
    order: 6
  },
  {
    group: '一会去吃什么？',
    label: '面食🍜饺子🥟',
    weight: 1,
    order: 7
  },
  {
    group: '一会去吃什么？',
    label: '炸鸡🍗汉堡🍔',
    weight: 1,
    order: 8
  },
  {
    group: '一会去吃什么？',
    label: '自己动手丰衣足食',
    weight: 1,
    order: 9
  },

  {
    group: '真心话&大冒险',
    label: '真心话',
    weight: 1,
    order: 10
  },
  {
    group: '真心话&大冒险',
    label: '大冒险',
    weight: 1,
    order: 11
  },
  {
    group: '真心话&大冒险',
    label: '再转一次',
    weight: 1,
    order: 12
  },

  {
    group: '选一个真心话',
    label: '说一个不敢告诉爸妈的事',
    weight: 1,
    order: 13
  },
  {
    group: '选一个真心话',
    label: '你对左边第三个异性有什么感觉？要具体。',
    weight: 1,
    order: 14
  },
  {
    group: '选一个真心话',
    label: '最喜欢的食物是什么',
    weight: 1,
    order: 15
  },
  {
    group: '选一个真心话',
    label: '你心目中理想的爱人是什么样子呢？',
    weight: 1,
    order: 16
  },
  {
    group: '选一个真心话',
    label: '最想实现的三个愿望是什么？',
    weight: 1,
    order: 17
  },
  {
    group: '选一个真心话',
    label: '你想和在场的哪一位玩大冒险？',
    weight: 1,
    order: 18
  },
  {
    group: '选一个真心话',
    label: '哭得最伤心的是哪一次？为什么？',
    weight: 1,
    order: 19
  },
  {
    group: '选一个真心话',
    label: '你觉得自己长的如何。',
    weight: 1,
    order: 20
  },
  {
    group: '选一个真心话',
    label: '你最怕的事情或东西是什么（说出三件）。',
    weight: 1,
    order: 21
  },

  {
    group: '选一个大冒险',
    label: '原地转十圈然后靠一个人身上',
    weight: 1,
    order: 22
  },
  {
    group: '选一个大冒险',
    label: '把右边第一个异性横抱起来',
    weight: 1,
    order: 23
  },
  {
    group: '选一个大冒险',
    label: '抱着正对面人的大腿唱情歌',
    weight: 1,
    order: 24
  },
  {
    group: '选一个大冒险',
    label: '给最近的人擦嘴巴',
    weight: 1,
    order: 25
  },
  {
    group: '选一个大冒险',
    label: '双手揪耳朵说“我错了”并做蛙跳5个',
    weight: 1,
    order: 26
  },
  {
    group: '选一个大冒险',
    label: '选择一名异性向她他深情表白',
    weight: 1,
    order: 27
  },
  {
    group: '选一个大冒险',
    label: '大喊“燃烧吧小宇宙”（超大声）',
    weight: 1,
    order: 28
  },
  {
    group: '选一个大冒险',
    label: '表演大猩猩捶胸呐喊“嗷呜~嗷呜~嗷呜~”',
    weight: 1,
    order: 29
  },
  {
    group: '选一个大冒险',
    label: '先大笑五秒，再大哭五秒',
    weight: 1,
    order:30
  },

];
